

<script setup lang="ts">
import LogoBMBF from '~/assets/svgs/bmbf_logo.svg'
import LogoUL from '~/assets/svgs/ul_logo.svg'
import { useText } from '~/composables/useText'

const { staticText } = useText()
</script>

<template>
  <div class="relative hidden bg-gray-25/20 py-[30px] lg:block">
    <div
      class="container mx-auto grid w-full grid-cols-4 gap-20 lg:grid-cols-12"
    >
      <div class="col-span-4 flex flex-col flex-wrap lg:col-span-6">
        <span class="w-full">{{ staticText.app.organizedBy }}</span>
        <div class="mt-5 flex flex-nowrap items-start gap-x-10">
          <LogoUL class="w-2/3" />
          <NuxtImg
            class="max-h-[200px] w-1/3"
            src="/images/crossarea-old_logo.png"
            height="228px"
            width="200px"
            sizes="sm:270px lg:300px xl:179px"
            densities="x1 x2"
            alt="Crossarea e.V. Logo"
            fit="contain"
            format="avif"
            placeholder
            loading="lazy"
          />
        </div>
      </div>
      <div class="col-span-4 flex flex-col flex-wrap items-start lg:col-span-4">
        <span class="w-full lg:col-start-1 lg:col-end-4">{{
          staticText.app.fundedBy
        }}</span>
        <div class="mt-5 flex flex-nowrap items-start gap-x-10">
          <LogoBMBF class="w-1/2" />
          <NuxtImg
            class="max-h-[100px] w-1/2 object-contain"
            src="/images/rig_logo.png"
            height="207px"
            width="287px"
            sizes="sm:270px lg:300px xl:179px"
            densities="x1 x2"
            alt="Research in Germany Logo"
            fit="contain"
            format="avif"
            placeholder
            loading="lazy"
          />
        </div>
      </div>
      <div class="col-span-4 flex flex-wrap gap-x-10 lg:col-span-2">
        <span class="w-full">{{ staticText.app.distinguishedBy }}</span>
        <NuxtImg
          src="/images/dfg-community_logo.png"
          alt="DFG Logo"
          class="mt-5 max-h-[150px] w-full object-contain"
        />
      </div>
    </div>
  </div>
</template>
